$breakpoint:992px !default;
$scale:14px !default;

@import './_fonts';
@import './_animation';
@import './_layout_light';
@import './_layout_dim';
@import './_layout_dark';
@import './_main';
@import './_sidebar_vertical';
@import './_sidebar_slim';
@import './_sidebar_slim_plus';
@import './_sidebar_horizontal';
@import './_sidebar_reveal';
@import './_sidebar_drawer';
@import './menutheme/_menutheme';
@import './_responsive';
@import './_topbar';
@import './_breadcrumb';
@import './_profile';
@import './_config';
@import './_content';
@import './_typography';
@import './_utils';


#sidebar-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
#sidebar-logo{
    width: 2.5rem;
    margin-right: 10px;
}
#sidebar-title{
    font-family: 'thesimssansbold';
    font-size: 1.5rem;
    color: #002945;
}