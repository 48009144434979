@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "assets/layout/styles/layout/layout.scss";
@import "assets/layout/theme.css";
@import "/node_modules/primeflex/primeflex.scss";
@import "/node_modules/angular-calendar/css/angular-calendar.css";

@font-face {
  font-family: thesimssansbold;
  src: url(assets/layout/fonts/thesimssansbold.otf) format("opentype");
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

:root {
  --primary-color-orange: #ED7445;
}


.hide-mobile {
  display: block;
}
.show-mobile {
  display: none;
}
.app-page {
  min-height: calc(100vh - 150px);
}

@media (max-width: 768px) {
  .show-mobile {
    display: block;
  }
  .hide-mobile {
    display: none !important;
  }
  .app-page {
    margin-bottom: 100px !important;
  }

  .empty-content-container {
    width: 100%;
    height: calc(100vh - 300px) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.empty-content-container {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}

:host ::ng-deep eyeicon,
:host ::ng-deep eyeslashicon {
  transform: scale(1.6);
  right: 1rem !important;
  color: var(--primary-color) !important;
}

ngx-material-timepicker-container {
  position: relative;
  z-index: 10000;
}

::-webkit-scrollbar {
  display: none;
}
